import type { IStudent } from '/@/app/models/interfaces';
import ObjectID from 'bson-objectid';

export const sortStudents = (students: IStudent[]) => {
  return students.sort((a: IStudent, b: IStudent) => {
    const partsA = a.fullName.split(' ');
    const lastNameA = partsA[partsA.length - 1];
    const partsB = b.fullName.split(' ');
    const lastNameB = partsB[partsB.length - 1];
    return lastNameA.localeCompare(lastNameB, 'he');
  });
};

export function generateObjectId(): string {
  const objectID: any = new ObjectID();
  return objectID.toString();
}
